<template>
	<create-assets />
</template>
<script>
import CreateAssets from '@/components/inventory/assets/AssetsForm/AssetsForm.vue';

export default {
	name: 'CreateAssetsPage',
	components: { CreateAssets },
};
</script>
